import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Red Clay Retreat
			</title>
			<meta name={"description"} content={"Ваш приватний тенісний притулок"} />
			<meta property={"og:title"} content={"Red Clay Retreat"} />
			<meta property={"og:description"} content={"Ваш приватний тенісний притулок"} />
			<meta property={"og:image"} content={"https://bravande.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bravande.com/img/502142.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bravande.com/img/502142.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bravande.com/img/502142.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bravande.com/img/502142.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bravande.com/img/502142.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bravande.com/img/502142.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://bravande.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="#ffffff"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
					Про нас
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
					Red Clay Retreat народився з пристрасті до тенісу та бажання створити унікальний і спокійний простір для гравців, щоб вони могли насолоджуватися цим видом спорту. Наш засновник уявляв собі місце, де любителі тенісу могли б відволіктися від повсякденних турбот і зануритися в гру. Як тільки ви ступите на наш ретельно доглянутий глиняний корт, ви відчуєте різницю. Заспокійлива атмосфера в поєднанні з високоякісним ігровим покриттям дарує неперевершені враження від гри в теніс.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Our Philosophy
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				We operate on the principle that excellence on the football field is achieved through discipline, perseverance, and a deep understanding of the game. Our approach is comprehensive, focusing on physical conditioning, skill development, tactical knowledge, and mental toughness.
			</Text>
			<Box min-width="100px" min-height="100px" display="grid" grid-gap="50px 50px">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Історія Red Clay Retreat
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Наш шлях почався з простої ідеї: запропонувати єдиний глиняний корт преміум-класу, де гравці всіх рівнів могли б грати без шуму і натовпу на великих майданчиках. Ми віримо, що суть тенісу полягає в його простоті та витонченості, і наш корт відображає цю філософію. Кожна деталь, від текстури глини до частково закритої конструкції, була ретельно продумана, щоб забезпечити найкращі умови для гри.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Унікальні особливості нашого корту
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Наш глиняний корт є центральним елементом Red Clay Retreat, розроблений для того, щоб запропонувати винятковий ігровий досвід. Глиняне покриття не тільки легше для ваших суглобів, але й дозволяє проводити стратегічні, більш тривалі ралі. Наш частково закритий дизайн гарантує, що ви можете грати з комфортом незалежно від погоди, забезпечуючи тінь у сонячні дні та захист від дощу.
						<br />
						<br />
						На додаток до корту, ми пропонуємо цілий ряд зручностей, які зроблять ваш візит більш приємним. Наша комфортабельна лаунж-зона - ідеальне місце для відпочинку до або після гри. Ми пропонуємо безкоштовні напої, а наш персонал завжди готовий допомогти з будь-якими вашими потребами. Ми прагнемо зробити кожен візит до Red Clay Retreat незабутнім, починаючи від якості наших зручностей і закінчуючи персоналізованим обслуговуванням, яке ви отримуєте.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Станьте частиною досвіду Red Clay Retreat
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Створіть свою тенісну історію разом з нами в Red Clay Retreat. Якщо ви хочете вдосконалити свої навички, насолодитися неспішною грою або просто відволіктися від повсякденної рутини, наш корт пропонує ідеальні умови. Відчуйте спокій та ексклюзивність, які відрізняють нас від інших тенісних об'єктів. Ми прагнемо надати вам унікальний і приємний досвід гри в теніс, до якого ви захочете повертатися знову і знову.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://bravande.com/img/5.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://bravande.com/img/6.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Red Clay Retreat - вдоскональте свою гру в теніс
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});